import { styled } from 'solid-styled-components';

export const StyledTreatmentWrapper = styled.div<{ sticky: boolean }>`
    position: relative;
    padding-top: ${(p) => (p.sticky ? '86px' : '')};
    height: 100%;
`;

export const StyledTreatmentHeader = styled.div<{ sticky: boolean; containerWidth?: number }>`
    position: ${(p) => (p.sticky ? 'fixed' : 'relative')};
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.95);
    padding: ${(p) => (p.sticky ? '1rem' : '2rem 0 1rem')};

    @media screen and (min-width: 769px) {
        left: auto;
        right: auto;
        width: ${(p) => (p.containerWidth ? `calc(${p.containerWidth}px - 2rem)` : 'auto')};
        padding: ${(p) => (p.sticky ? '1rem 0' : '2rem 0 1rem')};
    }

    h2 {
        margin: 0;
        text-align: center;
    }
`;

export const StyledTreatmentHeaderInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
`;

export const StyledToggleButton = styled.button`
    border: none;
    background: white;
    border: 1px solid #a4343a;
    color: #a4343a;
    cursor: default;
    opacity: 1;
    font-size: 14px;
    padding: 0.75rem;
    border-radius: 8px;
    transition: opacity 100ms;
    margin-right: 0.5rem;
    cursor: pointer;

    @media screen and (min-width: 769px) {
        margin-right: 1rem;
        padding: 0.75rem;
    }

    &:disabled {
        opacity: 0;
    }
`;

export const StyledButtonWrapper = styled.button`
    border: none;
    border: 1px solid #a4343a;
    background: #a4343a;
    color: white;
    cursor: default;
    opacity: 1;
    font-size: 14px;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    transition: opacity 100ms;
    font-weight: bold;
    cursor: pointer;

    @media screen and (min-width: 769px) {
        padding: 0.75rem 1rem;
    }

    &:disabled {
        opacity: 0.75;
        cursor: default;
    }
`;

export const StyledCombinedTreatmentWrapper = styled.div`
    margin-bottom: 2rem;
`;
