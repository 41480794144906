import { Link } from '@solidjs/router';
import { styled } from "solid-styled-components";
import { colors } from '../../style/style-variables';

export const StyledContactUsLink = styled(Link)`
    background-color: ${colors.red};
    position: fixed;
    right: 0.5rem;
    max-width: calc(100% - 1rem);
    bottom: 1rem;
    z-index: 1000;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    color: white !important;
    font-size: 15px;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;

    @media screen and (min-width: 769px) {
        padding: 0.5rem 2rem;
        right: 2rem;
        bottom: 2rem;
        font-size: 18px;
        gap: 1rem;
    }
`;
