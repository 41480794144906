import { createEffect, createSignal, For, onCleanup, onMount } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import CheckboxBar from '../checkbox-bar/checkbox-bar';
import { Header } from '../layout/header/header';
import { Container } from '../layout/container/container';
import type { Treatment, TreatmentsProps } from './treatments.d';
import { useLocation, useNavigate } from '@solidjs/router';
import {
    StyledButtonWrapper,
    StyledToggleButton,
    StyledTreatmentHeader,
    StyledTreatmentWrapper,
    StyledCombinedTreatmentWrapper,
    StyledTreatmentHeaderInner,
} from './treatments.styles';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import { isLocalStorageAvailable } from '../../../lib/localstorage';
import { AdsContainer } from '../../containers/ads/ads-container';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

const LOCAL_STORAGE_KEY = 'selected-treatments';
type LocalStorageType = {
    pathname: string;
    treatments: string[];
};

export const Treatments = (props: TreatmentsProps) => {
    const navigate = useNavigate();
    const category = () => props.cropTypeCategoryData()?.cropTypeCategory;
    const cropType = () => props.cropTypeData()?.cropType;
    const strategies = () => props.strategiesData()?.strategies;
    const crop = () => props.cropData()?.crop;
    const purpose = () => props.purposeData()?.purpose;
    const prompt = 'Välj mål';
    let containerRef: HTMLDivElement | undefined;

    const [sticky, setSticky] = createSignal(false);
    const [containerWidth, setContainerWidth] = createSignal(0);

    const handleScroll = () => {
        const target = window?.innerWidth > 769 ? 300 : 86;
        setSticky(window?.scrollY > target);
    };

    const parseContainerWidth = () => {
        setContainerWidth(containerRef?.clientWidth || 0);
    };

    createEffect(() => {
        parseContainerWidth();
    });

    onMount(() => {
        window?.addEventListener('scroll', handleScroll);
        window?.addEventListener('resize', parseContainerWidth);

        onCleanup(() => {
            window?.removeEventListener('scroll', handleScroll);
            window?.removeEventListener('resize', parseContainerWidth);
        });
    });

    const getInitialCheckedTreatments = (): string[] => {
        if (!isLocalStorageAvailable()) {
            return [];
        }

        const storage = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!storage) {
            return [];
        }

        const parsed: LocalStorageType = JSON.parse(storage);
        if (!parsed || useLocation().pathname !== parsed.pathname) {
            return [];
        }

        return parsed.treatments;
    };
    const [checkedTreatments, setCheckedTreatments] = createSignal<string[]>(getInitialCheckedTreatments());
    const [availableTreatments, setAvailableTreatments] = createSignal<string[]>([]);

    createEffect(() => {
        calculateAvailableTreatments();
    });

    function isTreatmentDisabled(treatment: Treatment) {
        // This occurs when no treatments are selected!
        if (availableTreatments().length === 0) {
            return false;
        }

        return !availableTreatments().includes(treatment.slug);
    }

    const toggleTreatment = (slug: string, checked = true) => {
        const index = checkedTreatments().findIndex((item) => item === slug);
        const t = [...checkedTreatments()];

        if (checked && index === -1) {
            t.push(slug);
        }

        if (!checked && index > -1) {
            t.splice(index, 1);
        }

        setCheckedTreatments(t);
        calculateAvailableTreatments();
    };

    const calculateAvailableTreatments = () => {
        if (!checkedTreatments().length) {
            setAvailableTreatments([]);
            return;
        }

        const strategyTreaments = strategies()
            ?.rows.map(({ treatments }) => treatments.map(({ slug }) => slug))
            .filter((s) => checkedTreatments().every((checkedTreatment) => s.includes(checkedTreatment)))
            .flat();

        const availableSet = [...new Set(strategyTreaments)];

        setAvailableTreatments(availableSet);
    };

    const filteredTreatments = () => {
        if (!strategies()?.rows || !purpose()) {
            return [];
        }

        const strategyTreatments = new Set();
        strategies()?.rows.forEach(({ treatments }) => {
            treatments.forEach(({ slug }) => strategyTreatments.add(slug));
        });

        return (
            purpose()
                ?.treatments?.filter(({ slug }) => strategyTreatments.has(slug))
                ?.filter(({ combined }) => !combined) || []
        );
    };

    const filteredCombinedTreatments = () => {
        if (!strategies()?.rows || !purpose()) {
            return [];
        }

        const strategyTreatments = new Set();
        strategies()?.rows.forEach(({ treatments }) => {
            treatments.forEach(({ slug }) => strategyTreatments.add(slug));
        });

        return (
            purpose()
                ?.treatments?.filter(({ slug }) => strategyTreatments.has(slug))
                ?.filter(({ combined }) => combined) || []
        );
    };

    const resetTreatments = () => {
        setCheckedTreatments([]);
        localStorage?.removeItem(LOCAL_STORAGE_KEY);
    };

    const next = () => {
        if (isLocalStorageAvailable()) {
            const value: LocalStorageType = {
                pathname: `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}/${purpose()?.slug}`,
                treatments: checkedTreatments(),
            };

            localStorage?.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
        }

        navigate(
            `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}/${purpose()?.slug}/resultat?mal=${checkedTreatments().join(',')}`,
        );
    };

    const links = () => [
        {
            text: category()?.name,
            url: `/strategier/${category()?.slug}/`,
        },
        {
            text: cropType()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}`,
        },
        {
            text: crop()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}`,
        },
        {
            text: purpose()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}/${purpose()?.slug}`,
        },
    ];

    const metaTitle = () =>
        `${purpose()?.name || ''} | ${crop()?.name || ''} | ${cropType()?.name || ''} | ${category()?.name || ''} - Gullvikspraktikan`;

    return (
        <>
            <BreadCrumbs links={links()} prompt={`${prompt}...`} />
            <Title>{metaTitle()}</Title>
            <Header header={crop()?.name} />
            <Container ref={containerRef}>
                <StyledTreatmentHeader sticky={sticky()} containerWidth={containerWidth()}>
                    <StyledTreatmentHeaderInner>
                        <h2>{prompt}</h2>
                        <div>
                            <StyledToggleButton disabled={!checkedTreatments().length} onClick={resetTreatments}>
                                Rensa val
                            </StyledToggleButton>
                            <StyledButtonWrapper onClick={() => next()} disabled={!checkedTreatments().length}>
                                Visa strategier
                            </StyledButtonWrapper>
                        </div>
                    </StyledTreatmentHeaderInner>
                </StyledTreatmentHeader>
                <StyledTreatmentWrapper sticky={sticky()}>
                    <StyledCombinedTreatmentWrapper>
                        <For each={filteredCombinedTreatments()}>
                            {(treatment) => (
                                <CheckboxBar
                                    label={treatment.name}
                                    disabled={isTreatmentDisabled(treatment)}
                                    highlighted={true}
                                    checked={checkedTreatments().includes(treatment.slug)}
                                    onChange={(checked: boolean) => toggleTreatment(treatment.slug, checked)}
                                />
                            )}
                        </For>
                    </StyledCombinedTreatmentWrapper>
                    <For each={filteredTreatments()} fallback={<SkeletonLoader />}>
                        {(treatment) => (
                            <CheckboxBar
                                label={treatment.name}
                                disabled={isTreatmentDisabled(treatment)}
                                checked={checkedTreatments().includes(treatment.slug)}
                                onChange={(checked: boolean) => toggleTreatment(treatment.slug, checked)}
                            />
                        )}
                    </For>
                </StyledTreatmentWrapper>
                <AdsContainer placement={Placements.Crop} slug={crop()?.slug} />
            </Container>
        </>
    );
};
