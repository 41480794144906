import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import { colorsRgb, colors } from '../../style/style-variables';

export const StyledStrategy = styled.div`
    background: rgba(${colorsRgb.yellow}, 0.2);
    border-radius: 12px 12px 0 0;
    padding: 1.5rem 1rem 0rem 1rem;

    button:hover {
        cursor: pointer;
    }

    ul {
        margin-bottom: 0;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: underline;
    text-underline-offset: 2px;

    svg {
        padding-left: 0.25rem;
        width: 18px;
        height: 12px;
        transform: rotate(-45deg);
    }
`;

export const StyledNoResults = styled(StyledStrategy)`
    text-align: center;
    padding: 2rem 3rem;

    p {
        font-size: 18px;
    }
`;

export const StyledTargets = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
`;

export const StyledStrategiesInfoAndControls = styled.div<{ numFilters: number }>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: ${(p) => (p.numFilters > 0 ? '1.5rem' : '')};

    .autumn-spring-filters {
        width: 100%;
        max-width: 400px;
        margin: ${(p) => (p.numFilters === 1 ? '0 auto' : '0')};
    }

    .effect-color-map {
        width: 100%;
        max-width: 400px;
        margin: ${(p) => (p.numFilters === 1 ? '0 auto' : '0')};
    }

    @media (min-width: 796px) {
        display: ${(p) => (p.numFilters > 0 ? 'grid' : 'grid')};
        grid-template-columns: ${(p) => (p.numFilters === 2 ? '1fr 1fr' : '1fr')};
        grid-gap: 64px;
        border-bottom: none;
        align-items: end;
        margin-bottom: ${(p) => (p.numFilters > 0 ? '0.75rem' : '0')};
        padding: ${(p) => (p.numFilters > 0 ? '0.75rem' : '0')};
    }
`;

export const StyledStrategyInformation = styled.div`
    white-space: pre-line;
    background-color: rgb(254, 250, 236);
    border: 1px solid #f5e5b8;
    border-top: none;
    border-right: none;
    border-radius: 8px;
    padding: 1rem;
    gap: 0.5rem;
    margin-bottom: 0.8rem;
    font-size: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 796px) {
        margin-bottom: 0;
        height: 100%;
        box-sizing: border-box;
    }
`;

export const StyledStrategyInformationHeader = styled.h5`
    margin: 0 0 0.5rem;
    font-size: 14px;

    @media (min-width: 796px) {
        min-width: 180px;
    }
`;

export const StyledStrategyInformationContent = styled.div`
    ul ul {
        padding-top: 0.25rem;
    }
`;

export const StyledStrategyInformationContainer = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;

    &.is-visible {
        margin-top: 0.8rem;
        height: auto;
        max-height: 1000px;
    }
`;

export const StyledStrategyHeader = styled.div`
    margin-left: 0.5rem;

    @media (min-width: 796px) {
        margin-left: 1rem;
    }
`;

export const StyledStrategyContent = styled.div`
    margin-bottom: 1rem;

    @media (min-width: 796px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.8rem;
        border-bottom: none;
        align-items: end;
        margin-bottom: 1rem;

        > *:nth-child(1):nth-last-child(1) {
            grid-column: span 2;
        }
    }
`;

export const StyledRoundedTag = styled.div`
    border-radius: 50px;
    background-color: ${colors.red};
    color: white;
    font-size: 14px;
    padding: 0.5rem 1rem;
    font-weight: 600;
`;

export const StyledEffectColorMapHeadline = styled.div`
    text-align: center;
    font-size: 14px;
    margin-bottom: 0.5rem;

    @media (min-width: 796px) {
        font-size: 16px;
    }
`;

export const StyledEffectMap = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 600;
    gap: 0.25rem;
`;

type ColorKey = keyof typeof colors;
export const StyledEffectMapSpan = styled.div<{ color: ColorKey }>`
    border-radius: 50px;
    background-color: ${p => colors[p.color] || colors.white};
    color: ${p => (p.color === 'green' ? colors.white : colors.black)};
    font-size: 14px;
    padding: 0.35rem 0.5rem;
    font-weight: 600;
    width: 25%;
    text-align: center;

    @media (min-width: 796px) {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
    }
`;

export const StyledTreatmentTags = styled.ul`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 0;
`;

export const StyledNotShownTreatmentsCount = styled.div`
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: ${colors.red};
    cursor: pointer;
`;

export const StyledSpringAutumHeader = styled.p`
    margin: 0 auto 0.75rem;
    text-align: center;
    font-size: 16px;
`;

export const StyledSpringAutumChoicesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
`;

export const StyledSpringAutumnChoice = styled.button<{ isActive: boolean }>`
    border: none;
    background: ${p => (p.isActive ? colors.red : colors.lightgray)};
    color: ${p => (p.isActive ? colors.white : colors.black)};
    cursor: ${p => (p.isActive ? 'default' : p.disabled ? 'default' : 'pointer')};
    opacity: ${p => (p.disabled ? 0.5 : 1)};
    font-size: 14px;
    font-weight: 600;
    padding: 0.75rem;
    border-radius: 8px;
    transition:
        background 150ms ease,
        color 150ms ease;
    width: 33%;
`;

export const StyledStrategyToggle = styled.div`
    width: 100%;
    background-color: #fde49b;
    border-radius: 0 0 12px 12px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    padding: 16px 0;
    margin-bottom: 1.5rem;
    cursor: pointer;
`;

export const StyledSpringAutumnTreatmentText = styled.p`
    font-size: 14px;
`;

export const StyledStrategyNameHeading = styled.h3`
    margin-bottom: 0;
`;

export const StyledStrategyToggleText = styled.p`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: ${colors.red};
`;

export const StyledAdditionalTreatments = styled.div`
    margin-bottom: 2rem;
`;

export const StyledAdditionalTreamentsHeadline = styled.h4`
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;

export const StyledDivider = styled.div`
    border-top: 1px solid ${colors.green};
    opacity: 0.15;
    margin-bottom: 1rem;
`;

export const StyledTreatmentsWrapper = styled.div`
    margin-bottom: 1rem;
`;

export const StyledShowMoreStrategies = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;
