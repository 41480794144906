import { styled } from 'solid-styled-components';
import { colors } from '../../../style/style-variables';

export const StyledContainerWrapper = styled.div`
    background: ${colors.red};
    border-radius: 0 0 12px 12px;
`;

export const StyledContainer = styled.div`
    padding: 1rem;
    background: white;
    color: #262626;
    min-height: calc(100vh - 70px);

    @media screen and (max-width: 769px) {
        padding-bottom: 6rem;
    }

    @media (min-width: 796px) {
        border-radius: 0 0 12px 12px;
        min-height: 50vh;
    }
`;

export const StyledHeader = styled.h2`
    margin: 1rem 0;
    text-align: center;
    color: ${colors.black};

    @media (min-width: 796px) {
        margin: 2rem 0 1rem 0;
    }
`;
