import { createSignal, Show, For, onMount, createEffect } from 'solid-js';
import { SelectOption, SelectMultipleProps } from './select.types';
import { SearchField } from './search-field/search-field';
import {
    StyledSelectComponent,
    StyledSelectWrapper,
    StyledSelectField,
    StyledSelectOptions,
    StyledMultiSelectOption,
    StyledLabel,
    StyledArrow,
    StyledSelectedOptions,
    StyledSelectedOption,
    StyledSelectedOptionDeleteIcon,
    StyledArrowIcon,
} from './select.styles';
import { Row } from '../../../isomorphic/components/layout/row/row';
import { StyledHorizontalSpace } from '../../../isomorphic/components/layout/layout.styles';

export const MultiSelect = (props: SelectMultipleProps & { mergeDuplicates?: boolean }) => {
    onMount(() => {
        document.addEventListener('click', handleClickOutside, false);
    });

    let selectWrapper: any;
    let searchField: any;
    const [open, setOpen] = createSignal(false);
    const [searchFieldQuery, setSearchFieldQuery] = createSignal('');
    const [visibleOptions, setVisibleOptions] = createSignal(props.options);

    const handleClickOutside = (e: any) => {
        const inside = selectWrapper.contains(e.target);
        if (!inside) {
            setOpen(false);
        }
    };

    const currentlySelectedOptions = () => {
        const selectedValuesSet = new Set(props.value);
        return props.options.filter(option => {
            const optionIndices = option.value.split(',');
            return optionIndices.some(index => selectedValuesSet.has(index));
        });
    };

    const handleOptionClick = (val: string) => {
        const selectedValuesSet = new Set(props.value);
        const optionIndices = val.split(',');
    
        const anySelected = optionIndices.some(index => selectedValuesSet.has(index));
    
        if (anySelected) {
            optionIndices.forEach(index => selectedValuesSet.delete(index));
        } else {
            optionIndices.forEach(index => selectedValuesSet.add(index));
        }
    
        props.onChange([...selectedValuesSet]);
    };

    const handleSelectedOptionClick = (val: string) => {
        const selectedValuesSet = new Set(props.value);

        if (props.mergeDuplicates && val.includes(',')) {
            val.split(',').forEach(index => selectedValuesSet.delete(index));
        } else {
            selectedValuesSet.delete(val);
        }

        props.onChange([...selectedValuesSet]);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const searchableSelectValueFieldContent = () => {
        if (open()) {
            return (
                <StyledSelectedOptions>
                    <For each={currentlySelectedOptions()}>
                        {(item: SelectOption) => (
                            <StyledSelectedOption onClick={() => handleSelectedOptionClick(item.value)}>
                                <span innerHTML={item.text} />
                                <StyledSelectedOptionDeleteIcon>
                                    <span>x</span>
                                </StyledSelectedOptionDeleteIcon>
                            </StyledSelectedOption>
                        )}
                    </For>
                    <Row alignItems="center">
                        <StyledHorizontalSpace size={1} />
                        <SearchField
                            options={props.options}
                            reportVisibleOptions={(visibleOptions: SelectOption[]) => {
                                setVisibleOptions(visibleOptions);
                            }}
                            value={searchFieldQuery()}
                            onSearchFieldChange={(val: string) => setSearchFieldQuery(val)}
                            ref={searchField}
                        />
                    </Row>
                </StyledSelectedOptions>
            );
        } else {
            return (
                <StyledSelectedOptions>
                    <For each={currentlySelectedOptions()}>
                        {(item: SelectOption) => (
                            <StyledSelectedOption onClick={() => handleSelectedOptionClick(item.value)}>
                                <span innerHTML={item.text} />
                                <StyledSelectedOptionDeleteIcon>
                                    <span>x</span>
                                </StyledSelectedOptionDeleteIcon>
                            </StyledSelectedOption>
                        )}
                    </For>
                </StyledSelectedOptions>
            );
        }
    };

    return (
        <StyledSelectWrapper ref={selectWrapper}>
            <Show when={props.label}>
                <StyledLabel>
                    <span>{props.label}</span>
                </StyledLabel>
            </Show>
            <StyledSelectComponent>
                <StyledSelectField onClick={handleOpen} disabled={props.disabled}>
                    {props.searchable ? (
                        <>
                            {searchableSelectValueFieldContent()}
                            <StyledArrow open={open()} />
                        </>
                    ) : (
                        <>
                            <Show
                                when={currentlySelectedOptions()?.length}
                                fallback={<strong>{props.placeholder ? props.placeholder : ''}</strong>}
                            >
                                <StyledSelectedOptions>
                                    <For each={currentlySelectedOptions()}>
                                        {(item: SelectOption) => (
                                            <StyledSelectedOption onClick={() => handleSelectedOptionClick(item.value)}>
                                                <span innerHTML={item.text} />
                                                <StyledSelectedOptionDeleteIcon>
                                                    <span>x</span>
                                                </StyledSelectedOptionDeleteIcon>
                                            </StyledSelectedOption>
                                        )}
                                    </For>
                                </StyledSelectedOptions>
                            </Show>
                            <StyledArrow open={open()} />
                        </>
                    )}
                    <StyledArrowIcon>
                        <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.30742 4.43048L10.4708 0.311734C10.891 -0.103911 11.5722 -0.103911 11.9923 0.311734C12.4125 0.72738 12.4125 1.40128 11.9923 1.81692L7.06817 6.68827C6.64801 7.10392 5.9668 7.10391 5.54665 6.68826L0.622572 1.81691C0.202422 1.40126 0.20243 0.727367 0.622588 0.311726C1.04275 -0.103916 1.72395 -0.103908 2.1441 0.311742L6.30742 4.43048Z"
                                fill="#151515"
                            />
                        </svg>
                    </StyledArrowIcon>
                </StyledSelectField>
                <Show when={open()}>
                    <StyledSelectOptions>
                        <For each={visibleOptions()} fallback={<div>No options</div>}>
                            {(option: SelectOption) => {
                                const selected = () => props.value.includes(option.value);

                                return (
                                    <StyledMultiSelectOption selected={selected()} onClick={() => handleOptionClick(option.value)}>
                                        <span innerHTML={option.text} />
                                    </StyledMultiSelectOption>
                                );
                            }}
                        </For>
                    </StyledSelectOptions>
                </Show>
            </StyledSelectComponent>
        </StyledSelectWrapper>
    );
};
