import { useLocation } from '@solidjs/router';
import { JSXElement, Show } from 'solid-js';
import { DesktopCategoriesMenu } from '../../desktop-categories-menu/desktop-categories-menu';
import { StyledScreen, StyledScreenWrapper } from './screen.styles';
import { StyledContactUsFab } from '../../contact-us-fab/contact-us-fab';

export const Screen = (props: { children: JSXElement | JSXElement[] }) => {
    const path = useLocation().pathname;

    // Only let StyledScreen take up full width on the products page.
    const screenUseMaxWidth = path.includes('/kategorier/produkter') ? false : true;

    // due to how the desktop layout is right now this is one way of solving it.
    // we do not want multiple columns on the home page.
    const columns = useLocation().pathname === '/' ? 'auto' : 'auto 1fr';

    return (
        <StyledScreenWrapper data-component="screen-wrapper" columns={columns}>
            <Show when={useLocation().pathname !== '/'}>
                <DesktopCategoriesMenu />
            </Show>
            <StyledScreen screenUseMaxWidth={screenUseMaxWidth}>{props.children}</StyledScreen>
            <StyledContactUsFab />
        </StyledScreenWrapper>
    );
};
