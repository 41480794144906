import { Show, createEffect, createSignal } from 'solid-js';
import { useLocation } from '@solidjs/router';
import { StyledContactUsLink } from './contact-us-fab.styles';

export const StyledContactUsFab = () => {
    const [displayContactUsFab,setDisplayContactUsFab] = createSignal(false);
    const path = useLocation().pathname;

    createEffect(() => {
        const pathsWithoutContactUsFab = ['/kontakta-oss', '/'];
        const onForbiddenPath = pathsWithoutContactUsFab.includes(path);
        setDisplayContactUsFab(!onForbiddenPath);
    },path);

    return (
        <Show when={displayContactUsFab()}>
            <StyledContactUsLink href="/kontakta-oss/">
                Kontakta din Gullviksrepresentant
                <svg
                    fill="#fff"
                    height="32px"
                    width="32px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 512 512"
                >
                    <g>
                        <g>
                            <g>
                                <path d="M266.667,320c52.928,0,96-43.072,96-96v-64c0-52.928-43.072-96-96-96H96c-52.928,0-96,43.072-96,96v64     c0,52.928,43.072,96,96,96h10.667v53.333c0,4.309,2.603,8.213,6.592,9.856c1.301,0.555,2.709,0.811,4.075,0.811     c2.773,0,5.504-1.088,7.552-3.115L185.749,320H266.667z"/>
                                <path d="M416,128h-20.288c-3.008,0-5.867,1.28-7.893,3.499l-2.539,2.795c-2.155,2.368-3.115,5.568-2.645,8.725     c0.811,5.547,1.365,11.179,1.365,16.96v64c0,64.704-52.651,117.333-117.333,117.333h-65.195c-3.008,0-5.867,1.259-7.893,3.477     l-5.099,5.611c-2.048,2.261-3.051,5.291-2.709,8.341c0.341,3.029,1.941,5.781,4.437,7.552     C206.528,377.877,225.579,384,245.333,384h80.917l60.885,60.885c2.027,2.027,4.757,3.115,7.531,3.115     c1.365,0,2.773-0.256,4.075-0.811c3.989-1.643,6.592-5.547,6.592-9.856V384H416c52.928,0,96-43.072,96-96v-64     C512,171.072,468.928,128,416,128z"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </StyledContactUsLink>
        </Show>
    );
};
