import { styled } from 'solid-styled-components';

export const StyledScreenWrapper = styled.div<{ columns: string }>`
    position: relative;

    * {
        box-sizing: border-box;
    }

    @media screen and (max-width: 768px) {
        position: absolute;
        left: 0;
        right: 0;
        height: 100vh;
        transition: all 0.25s ease-in-out;
        &.slide-fade-enter,
        &.slide-fade-leave-active {
            transform: translateX(100%);
            opacity: 1;

            &.back {
                transform: translateX(-100%);
                opacity: 0;
            }
        }

        &.slide-fade-exit-active {
            opacity: 0;
        }
    }

    @media screen and (min-width: 769px) {
        transition: none;
        display: grid;
        grid-template-columns: ${(p) => p.columns};
        padding: 6rem 2rem 2rem;
        gap: 2rem;

        @media (min-width: 769px) {
            padding-bottom: 7rem;
        }

        .home & {
            padding: 0;
        }

        &.slide-fade-enter,
        &.slide-fade-leave-active {
            &.back {
                display: none;
                height: 0;
                opacity: 0;
            }
        }

        &.slide-fade-exit-active {
            opacity: 0;
            display: none;
            height: 0;
        }
    }

    .notice-fade-enter-active,
    .notice-fade-exit-active {
        transition: opacity 0.3s, transform 0.3s;
    }
    .notice-fade-enter,
    .notice-fade-exit-to {
        transform: translateY(75px);
        opacity: 0;
    }
    .notice-fade-enter {
        transform: translateY(-75px);
    }
`;

export const StyledScreen = styled.div<StyledScreenProps>`
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    max-width: ${(p) => (p.screenUseMaxWidth ? '882px' : '1337px')};

    .home & {
        max-width: none;
    }
`;
type StyledScreenProps = {
    screenUseMaxWidth: boolean;
};
