import { createResource, createSignal, For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getProductQuery } from '../../graphql/queries/products';
import { Dialog } from '../layout/dialog/dialog';
import { Tag } from '../tag/tag';
import { Product } from '../products/product';
import { StrategyProduct, StrategyProps } from './strategies.d';
import { Treatment } from './../treatments/treatments.d';
import {
    StyledStrategy,
    StyledStrategyHeader,
    StyledStrategyInformationContainer,
    StyledTreatmentTags,
    StyledStrategyToggle,
    StyledNotShownTreatmentsCount,
    StyledSpringAutumnTreatmentText,
    StyledStrategyNameHeading,
    StyledStrategyToggleText,
    StyledStrategyContent,
    StyledTreatmentsWrapper,
    StyledLink,
} from './strategies.styles';
import { StrategyInformation } from './strategy-information';

export const Strategy = (props: StrategyProps) => {
    const { graphQlClient } = useContext(AppContext);
    const [isOpen, setIsOpen] = createSignal(false);
    const [currentProduct, setCurrentProduct] = createSignal<string | null>(null);
    const [productData] = createResource(currentProduct, slug => graphQlClient(getProductQuery, { slug }));

    const allTreatments = () => {
        const treatmentsSet = new Set<Treatment>();

        props.purposes?.forEach(({ treatments }) => {
            treatments?.forEach(t => treatmentsSet.add(t));
        });

        return [...treatmentsSet];
    };

    const sortedStrategyProducts = () => {
        const parents = props.strategy.products.filter(({ children }) => children?.length);
        if (!parents.length) {
            return props.strategy.products;
        }

        const childUuids = parents
            .flatMap(({ children = [] }) => children)
            .map(({ uuid }) => uuid)
            .filter(uuid => uuid);

        return props.strategy.products.filter(({ uuid }) => !childUuids.includes(uuid));
    };

    const getChildProducts = (parent: StrategyProduct) => {
        const childUuids = parent.children?.map(({ uuid }) => uuid) || [];
        return props.strategy.products.filter(({ uuid }) => childUuids.includes(uuid));
    };

    const name = () =>
        sortedStrategyProducts()
            .map(({ name }) => name)
            .join(' + ') || '(Inget namn)';

    const treatmentInformation = () => {
        if (props.strategy.growthPeriodText) {
            return props.strategy.growthPeriodText;
        }

        if (props.strategy.growthPeriodEnd) {
            let str = `Stadium (BBCH) ${props.strategy.growthPeriodStart}`;
            if (props.strategy.growthPeriodEnd) {
                str += `-${props.strategy.growthPeriodEnd}`;
            }
            return str;
        }

        return '';
    };

    const springAutumnTreatment = () => {
        type treatmentPeriod = 'SPRING' | 'AUTUMN';
        if (!props.strategy.springAutumnTreatment) {
            return null;
        }

        const treatmentPeriodDictionary = {
            SPRING: 'Vårbehandling',
            AUTUMN: 'Höstbehandling',
        };

        return treatmentPeriodDictionary[props.strategy.springAutumnTreatment as treatmentPeriod];
    };

    const handleModalOpen = (e: Event, product: any) => {
        e.stopPropagation();
        e.preventDefault();
        setCurrentProduct(product.slug);
    };

    const dict = {
        MILLIGRAM: 'mg',
        GRAM: 'g',
        KILO: 'kg',
        MILLILITER: 'ml',
        LITER: 'l',
        PROCENT: '%',
    };
    const getProductUnit = (unit: keyof typeof dict) => dict[unit];

    const getColorFromEffect = (effect: number) => {
        if (effect >= 90) {
            return 'green';
        }
        if (effect >= 70) {
            return 'yellow';
        }
        if (effect >= 40) {
            return 'lightblue';
        }

        return 'white';
    };

    const strategyTreatmentsFilteredBySelectedTreatments = () =>
        [...props.strategy.treatments].filter((t) => {
            return props.selectedTreatments.includes(t.slug) && !props.combinedTreatmentSlugs.includes(t.slug);
        });

    const sortedSelectedStrategyTreatments = () =>
        [...strategyTreatmentsFilteredBySelectedTreatments()].sort((a, b) => b.effect - a.effect);

    const sortedStrategyTreatmentsWithoutSelected = () =>
        [...props.strategy.treatments]
            .filter((t) => {
                if (props.selectedTreatments.includes(t.slug)) {
                    return false;
                }

                const allTreatment = allTreatments().find((allTreatment) => allTreatment.slug === t.slug);

                return allTreatment?.combined !== true;
            })
            .sort((a, b) => {
                if (b.effect !== a.effect) {
                    return b.effect - a.effect;
                }

                return a.name.localeCompare(b.name, 'sv');
            });

    const moreTreatmentsHeader = () => {
        const hasCombinedTreamentsOnly = [...props.selectedTreatments].every((t) => props.combinedTreatmentSlugs.includes(t));
        return hasCombinedTreamentsOnly ? 'Denna strategi fungerar mot:' : 'Denna strategi fungerar även mot:';
    };

    return (
        <>
            <StyledStrategy>
                <div>
                    <StyledStrategyHeader>
                        <StyledStrategyNameHeading>{name()}</StyledStrategyNameHeading>
                        <Show when={springAutumnTreatment()} fallback={<div style={{ 'padding-top': '0.5rem' }} />}>
                            <StyledSpringAutumnTreatmentText>{springAutumnTreatment()}</StyledSpringAutumnTreatmentText>
                        </Show>
                    </StyledStrategyHeader>

                    <StyledStrategyContent>
                        <Show when={sortedSelectedStrategyTreatments().length}>
                            <StrategyInformation header="Effekt:">
                                <StyledTreatmentTags>
                                    <For each={sortedSelectedStrategyTreatments()}>
                                        {({ effect, name }) => <Tag color={getColorFromEffect(effect)}>{name}</Tag>}
                                    </For>
                                    <Show when={sortedStrategyTreatmentsWithoutSelected().length}>
                                        <StyledNotShownTreatmentsCount onClick={() => setIsOpen(true)}>
                                            {`+ ${sortedStrategyTreatmentsWithoutSelected().length}`} ytterligare mål
                                        </StyledNotShownTreatmentsCount>
                                    </Show>
                                </StyledTreatmentTags>
                            </StrategyInformation>
                        </Show>

                        <Show when={props.strategy.doseInfoText}>
                            <StrategyInformation header="Doseringsinformation:">
                                <div innerHTML={props.strategy.doseInfoText} />
                            </StrategyInformation>
                        </Show>

                        <StrategyInformation header="Dosering:">
                            <ul>
                                <For each={sortedStrategyProducts()}>
                                    {(product) => (
                                        <Show
                                            when={product.children?.length}
                                            fallback={
                                                <li>
                                                    {`${product.amount} ${getProductUnit(product.unit as keyof typeof dict)}`}&nbsp;
                                                    <StyledLink href="#" onClick={(e) => handleModalOpen(e, product)}>
                                                        {product.name}
                                                    </StyledLink>
                                                    &nbsp;{`per ${props.strategy.doseArea}`}
                                                    <Show when={product.doseInfoText}>
                                                        &nbsp;&nbsp;&nbsp;<strong>Notera:</strong>&nbsp;{product.doseInfoText}
                                                    </Show>
                                                </li>
                                            }
                                        >
                                            <li>
                                                <strong>{product.name}</strong>
                                                <ul>
                                                    <For each={getChildProducts(product)}>
                                                        {(child) => (
                                                            <li>
                                                                {`${child.amount} ${getProductUnit(child.unit as keyof typeof dict)}`}&nbsp;
                                                                <a href="#" onClick={(e) => handleModalOpen(e, child)}>
                                                                    {child.name}
                                                                </a>
                                                            </li>
                                                        )}
                                                    </For>
                                                </ul>
                                            </li>
                                        </Show>
                                    )}
                                </For>
                            </ul>
                        </StrategyInformation>
                    </StyledStrategyContent>
                </div>
                <StyledStrategyInformationContainer classList={{ 'is-visible': isOpen() }}>
                    <Show when={treatmentInformation() || props.strategy.information}>
                        <StyledStrategyContent>
                            <Show when={treatmentInformation()}>
                                <StrategyInformation header="Behandlingstidpunkt:">{treatmentInformation()}</StrategyInformation>
                            </Show>
                            <Show when={props.strategy.information}>
                                <StrategyInformation header="Att beakta:">
                                    <div innerHTML={props.strategy.information} />
                                </StrategyInformation>
                            </Show>
                        </StyledStrategyContent>
                    </Show>
                    <Show when={sortedStrategyTreatmentsWithoutSelected().length}>
                        <StyledTreatmentsWrapper>
                            <StrategyInformation header={moreTreatmentsHeader()}>
                                <StyledTreatmentTags>
                                    <For each={sortedStrategyTreatmentsWithoutSelected()}>
                                        {({ effect, name }) => <Tag color={getColorFromEffect(effect)}>{name}</Tag>}
                                    </For>
                                </StyledTreatmentTags>
                            </StrategyInformation>
                        </StyledTreatmentsWrapper>
                    </Show>
                </StyledStrategyInformationContainer>
            </StyledStrategy>
            <StyledStrategyToggle onClick={() => setIsOpen(!isOpen())}>
                <StyledStrategyToggleText>{isOpen() ? '-' : '+'}</StyledStrategyToggleText>
                <StyledStrategyToggleText>{isOpen() ? 'STÄNG' : 'VISA MER'}</StyledStrategyToggleText>
            </StyledStrategyToggle>

            <Dialog isOpen={currentProduct() !== null} onClose={() => setCurrentProduct(null)}>
                <Product productData={productData} showHeader />
            </Dialog>
        </>
    );
};
