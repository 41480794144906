import { colors } from '../../style/style-variables';
import { styled } from 'solid-styled-components';

export const StyledContacts = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledAddressContainer = styled.div`
    line-height: 1.6;
    width: 100%;
    text-align: left;
    padding: 1rem 0 2rem;

    * {
        margin: 0;
        padding: 0;
    }

    a {
        color: ${colors.red} !important;
    }

    @media (min-width: 920px) {
        max-width: 520px;
        padding-left: 0.75rem;
    }
`;

export const StyledContactHeadline = styled.h2`
    font-size: 24px;
    margin: 1rem 0;
    text-align: center;
`;

export const StyledNoContactsContainer = styled.div`
    width: 100%;
    text-align: center;
`;

export const StyledContactItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;

    @media (min-width: 920px) {
        width: 45%;
    }

    img {
        margin-bottom: 0.75rem;
        border-radius: 5px;
        box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
    }
`;

export const StyledContactName = styled.div<StyledContactNameProps>`
    font-size: 20px;
    font-weight: 700;
    color: ${colors.black};
    ${(p) => (!p.hasJobTitle ? 'margin-bottom: 0.75rem;' : '')}
    text-align: center;
`;
type StyledContactNameProps = {
    hasJobTitle?: boolean;
};

export const StyledContactTel = styled.div`
    background: ${colors.red};
    color: #fff;
    font-weight: 700;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    margin-bottom: 0.5rem;

    a {
        color: #fff;
        margin: 0 0.25rem;
    }
`;

export const StyledContactEmails = styled.div`
    text-align: center;
`;

export const StyledContactEmailLink = styled.a`
    color: ${colors.red};
    font-size: 18px;
    display: block;
    margin: 0.25rem;
`;

export const StyledJobTitle = styled.p`
    font-size: 16px;
    color: ${colors.black};
    text-align: center;
`;

export type StyledImageProps = {
    url: string;
};

export const StyledContactsList = styled.div<StyledContactsListProps>`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 0.5rem;

    @media (min-width: 920px) {
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 2rem;
        column-gap: 1rem;
        width: 100%;
        justify-content: ${(p) => (p.contactsLength && p.contactsLength > 1 ? 'flex-start' : 'center')};
    }
`;
export type StyledContactsListProps = {
    contactsLength?: number;
};

export const StyledSelectWrapper = styled.div`
    width: 100%;

    @media (min-width: 920px) {
        max-width: 520px;
    }
`;

export const StyledContactAreas = styled.div``;

export const StyledContactArea = styled.div`
    font-size: 16px;
    font-weight: 700;
`;
